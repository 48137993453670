<template>
  <div class="article-content">
    <!-- 头部 -->
    <div class="header-tool">
      <div class="del" @click="del">
        <img src="../../../assets/images/del.png" alt />
      </div>
      <div class="refresh">
        <img src="../../../assets/images/refresh.png" alt />
      </div>
    </div>
    <!-- 筛选 -->
    <div class="filtrate">
      <div class="header-title">
        <p>筛选</p>
      </div>
      <div class="header-from">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="mini"
          ref="formInline"
        >
          <el-form-item label="标题:" prop="keywords">
            <el-input v-model.trim="formInline.keywords"></el-input>
          </el-form-item>
          <el-form-item label="分类:" prop="class">
            <el-select v-model="formInline.class">
              <el-option
                :label="item.title"
                :value="item.id"
                v-for="(item, key) in classList"
                :key="key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型" prop="type">
            <el-select v-model="formInline.type">
              <el-option label="文字" value="1"></el-option>
              <el-option label="视频" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否热门" prop="hot">
            <el-select v-model="formInline.hot">
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button plain @click="loadListData()">查询</el-button>
            <el-button @click="resetForm('formInline')">重置</el-button>
            <el-button @click="goAddarticle">创建文章</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="文章标题"
          width="600"
          align="left"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="keywords"
          label="关键字"
          width="200"
          align="center"
        ></el-table-column>
        <el-table-column prop="type" label="类型" width="100" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.type == 1 ? "文字" : "视频" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="文章分类"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.class | fClass }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="100" align="center">
          <template slot-scope="scope">
            <span :class="scope.row.status == 1 ? 'no' : 'off'">{{
              scope.row.status == 1 ? "启用" : "关闭"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="hot" label="是否热门" width="100" align="center">
          <template slot-scope="scope">
            <span :class="scope.row.hot == 1 ? 'hot' : ''">{{
              scope.row.hot == 1 ? "是" : "否"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="plain" @click="goAddarticle(scope.row)"
              >编辑</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="del(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="0"
        :page-size="pagesize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { getart, getartClass, deleteart } from "@/api/article.js";
let that;
export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      formInline: {
        keywords: "",
        class: "",
        type: "",
      },
      page: 0,
      pagesize: 15,
      total: 10,
      classList: [],
    };
  },
  beforeCreate() {
    that = this;
  },
  created() {
    this.loadListData();
    let data = {
      pid: 0,
      page: 0,
      pagesize: 999,
    };
    getartClass(data).then((res) => {
      this.classList = res.data;
    });
  },
  methods: {
    // 全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection = p;
    },
    // 页码
    handleCurrentChange(val) {
      this.page = val - 1;
      this.loadListData();
    },
    // 跳转创建文章页面
    goAddarticle(row) {
      if (row.id) {
        this.$router.push(`/article_list/Addarticle?id=${row.id}`);
      } else {
        this.$router.push(`/article_list/Addarticle`);
      }
    },
    // 数据加载
    loadListData() {
      let data = {
        hot: this.formInline.hot,
        keywords: this.formInline.keywords,
        type: this.formInline.type,
        class: this.formInline.class,
        page: this.page,
        pagesize: this.pagesize,
      };
      if (!data["type"]) {
        delete data["type"];
      }
      if (!data["class"]) {
        delete data["class"];
      }
      getart(data).then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.total = res.num * 1;
      });
    },
    // 重置
    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    // 删除
    del(row) {
      let data = {
        ids: "",
      };
      if (row.id) {
        data.ids = row.id + "";
      } else {
        if (this.multipleSelection.length <= 0) {
          this.$message({
            showClose: true,
            message: "请先勾选要删除的数据",
            type: "warning",
          });
          return;
        }
        data.ids = this.multipleSelection.toString();
      }

      //  提示 是否删除
      this.$confirm("是否删除已勾选的文章?", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteart(data).then((res) => {
            if (res.code === 400200) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
            }
            this.loadListData();
          });
        })
        .catch(() => {});
    },
  },
  filters: {
    fClass(val) {
      let name = "";
      for (let i = 0; i < that.classList.length; i++) {
        if (that.classList[i].id == val) {
          name = that.classList[i].title;
          break;
        }
      }
      return name;
    },
  },
};
</script>
<style lang="less">
.article-content {
  // 顶部功能
  .header-tool {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .del {
      width: 100px;
      margin-right: 20px;
      cursor: pointer;
    }
    .refresh {
      width: 100px;
      cursor: pointer;
    }
    .interviewBtn {
      width: 130px;
      margin-right: 20px;
      cursor: pointer;
    }
    img {
      width: 100%;
      pointer-events: none;
    }
  }
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-top: 20px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 100px;
        padding-right: 20px;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
      .el-button--mini {
        padding: 5px 10px;
        background: transparent;
        color: rgb(4, 87, 102);
        font-size: 16px;
        &:hover {
          border: 1px solid rgb(105, 105, 105);
        }
      }
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 680px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .hot {
      font-weight: 700;
      color: rgb(170, 0, 0);
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  // 页码
  .page {
    // .el-pagination {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    // }
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
</style>